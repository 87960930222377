<template>
  <!-- <div class="container-fluid" :class="!localCineSubscribed && 'container-fluid-background'"> -->
  <div class="container-fluid container-fluid-background">
    
    <!-- News Page -->
    <!-- <div v-if="localCineSubscribed" class="container">
      <div class="col-12" v-if="clubNews.data && clubNews.data.length > 0">
        <app-section :title="$t('Noticias')"></app-section>
      </div>
      <div class="col-12 mb-5 mb-sm-4 news" v-for="(nw, i) in clubNews.data" v-bind:key="i">
        <div class="row">
          <div class="col-12 col-sm-6 col-md-4">
            <router-link class="a-no-decoration" :to="{ name: 'news', params: { slug: nw.slug } }">
              <img
                :src="(nw.main_image ? nw.main_image : 'https://via.placeholder.com/450x253/29c1dd/ffffff?text=' + nw.title)"
                class="poster" />
            </router-link>
          </div>
          <div class="col-12 col-sm-6 col-md-8">
            <router-link class="a-no-decoration" :to="{ name: 'news', params: { slug: nw.slug } }">
              <h4 class="text-white pt-3">{{ nw.title }}</h4>

              <p class="my-3 text-muted" v-html="nw.short_description"></p>
              <span class="text-white">{{ $t('Ver Más') }}</span>
            </router-link>
          </div>
        </div>
      </div>
    </div> -->

    <div class="login-container">
      <app-section title="Cine Club"></app-section>
      <div class="container cine-club-main justify-content-center">
        <!-- <div v-if="!cineClubSubscribed" class="row justify-content-center">
          <p  class="text-center h6 mt-5"
            v-html="$t('Regístrate y recibe lo más reciente de nuestra programación.')"></p>
        </div> -->
        <div class="row justify-content-center">
          <p  class="text-center h6 mt-5"
            v-html="$t('Regístrate y recibe lo más reciente de nuestra programación.')"></p>
        </div>
        <div class="row mt-5 justify-content-center text-center">
          <LoginRegisterForm class="col-6" form_type="cine_club" :inputs='[
            {
              type: "text",
              placeholder: $t("Nombre"),
              name: "name",
              max_length: 50,
              required: true
            },
            {
              type: "email",
              placeholder: $t("Correo electrónico"),
              name: "email",
              max_length: null,
              required: true
            }
          ]' :submit_text="$t('Suscríbete').toUpperCase()" :watch_change_prop="form_modal_open">
          </LoginRegisterForm>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoginRegisterForm from "@/components/forms/LoginRegisterForm";
import AppSection from "@/components/AppSection";
import { mapGetters } from "vuex";
export default {
  name: "CineClub",
  components: {
    LoginRegisterForm,
    AppSection
  },
  data: function () {
    return {
      form_modal_open: false,
      localCineSubscribed: false,
    }
  },
  computed: {
    ...mapGetters(["cineClubSubscribed", "clubHome"]),
    clubNews: function () {
      return this.clubHome && this.clubHome.news ? this.clubHome.news : []
    }
  },
  mounted() {
    this.localCineSubscribed = this.cineClubSubscribed;
    if (this.localCineSubscribed) {
      this.$store.dispatch("fetchClubHome")
    }
  },
  methods: {
    toggleFormModal() {
      this.form_modal_open = !this.form_modal_open;
    }
  },
  // watch: {
  //   cineClubSubscribed(subscribed) {
  //     if (subscribed) {
  //       this.$store.dispatch("fetchClubHome")
  //       setTimeout(() => {
  //         this.$router.push({name: "home"});
  //       }, 4000)
  //     } else {
  //       this.localCineSubscribed = subscribed;
  //     }
  //   }
  // }
}
</script>

<style scoped lang="scss">
.container-fluid-background {
  background-image: url("/img/cine-club/login-cover.png");
  background-repeat: no-repeat;
  min-height: 200vh;
  background-size: cover;
  background-position: center;
}

.cine-club-main {
  margin-top: -3vw;
  margin-bottom: -2vw;
  font-size: 1.6em;

  p {
    max-width: 900px;
  }
}

@media (max-width: 768px) {
  .cine-club-main {
    font-size: 1.2em;
  }
}

@import "src/assets/styles/colors";

.news {
  .poster {
    width: 100%;
  }
}

.search {
  background-color: $medium-blue-2;
  min-height: 40vh;
}
</style>