var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid container-fluid-background"},[_c('div',{staticClass:"login-container"},[_c('app-section',{attrs:{"title":"Cine Club"}}),_c('div',{staticClass:"container cine-club-main justify-content-center"},[_c('div',{staticClass:"row justify-content-center"},[_c('p',{staticClass:"text-center h6 mt-5",domProps:{"innerHTML":_vm._s(_vm.$t('Regístrate y recibe lo más reciente de nuestra programación.'))}})]),_c('div',{staticClass:"row mt-5 justify-content-center text-center"},[_c('LoginRegisterForm',{staticClass:"col-6",attrs:{"form_type":"cine_club","inputs":[
          {
            type: "text",
            placeholder: _vm.$t("Nombre"),
            name: "name",
            max_length: 50,
            required: true
          },
          {
            type: "email",
            placeholder: _vm.$t("Correo electrónico"),
            name: "email",
            max_length: null,
            required: true
          }
        ],"submit_text":_vm.$t('Suscríbete').toUpperCase(),"watch_change_prop":_vm.form_modal_open}})],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }