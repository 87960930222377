<template>
  <div>
    <div v-if="form_success">
      <h5 v-if="loginForm">{{ $t('Bienvenido!') }}</h5>
      <h5 class="pt-5" v-else>{{ $t('¡Gracias por registrarte en ViendoMovies!') }}</h5>
      <p v-if="form_success_content" v-html="form_success_content"></p>
    </div>
    <div v-else>
      <div v-if="loginForm" class="">
        <form id="contactForm" ref="form" v-on:submit.prevent="onSubmit">
          <div class="mb-4">
            <div class="form-group">
              <input class="form-control mb-3" :class="error_messages.email ? 'error' : ''" ref="email" name="email"
                type="text" :placeholder='$t("Correo electrónico")' />
              <p class="text-error d-none">
                {{ error_messages.email }}
              </p>

              <input class="form-control mb-3" :class="error_messages.password ? 'error' : ''" type="password"
                ref="password" name="password" :placeholder='$t("Contraseña")' />
              <p class="text-error d-none">
                {{ error_messages.password }}
              </p>
            </div>
          </div>
          <button class="btn btn-primary btn-block btn-lg" type="submit">{{ $t("Iniciar Sesión") }}</button>
          <a href="#" class="mb-5 forgot-pass" @click.prevent="forgotPassword()">{{ $t("Necesitas ayuda?") }}</a>
          <button class="btn mb-5 btn-block" @click.prevent="toggleLoginView">{{ $t("Crear una cuenta") }}</button>
        </form>
      </div>
      <div v-else class="">
        <form id="contactForm" ref="form" v-on:submit.prevent="onSubmitRegister">

          <div class="mb-4">
            <div class="form-group">
              <input class="form-control mb-3" :class="error_messages.name ? 'error' : ''" ref="name" name="name"
                type="text" :placeholder='$t("Nombre")' />
              <p class="text-error d-none">
                {{ error_messages.name }}
              </p>

              <input class="form-control mb-3" :class="error_messages.email ? 'error' : ''" ref="email" name="email"
                type="text" :placeholder='$t("Correo electrónico")' />
              <p class="text-error d-none">
                {{ error_messages.email }}
              </p>

              <input class="form-control mb-3 invisible position-absolute"
                :class="error_messages.password ? 'error' : ''" type="password" ref="password" name="password"
                :placeholder='$t("Contraseña")' />
              <p class="text-error d-none">
                {{ error_messages.password }}
              </p>

            </div>
          </div>
          <button class="btn btn-primary btn-block mb-5 btn-lg" type="submit">{{ $t("Registrarme") }}</button>
          <!-- <button class="btn mb-5 btn-lg" @click.prevent="toggleLoginView">{{$t("Iniciar Sesión")}}</button> -->
        </form>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Form",
  props: {
    loginForm: {
      type: Boolean,
      default: false
    },
    form_success_content: {
      type: String,
      default: ''
    },
    watch_change_prop: {
    },
  },
  data: function () {
    return {
      error_messages: {
        email: "",
        password: "",
        name: ""
      },
      file_name: "",
      form_success: false
    };
  },
  computed: {
    submitEnabled() {
      return true;
    }
  },
  watch: {
    watch_change_prop() {
      // the watch_change_prop is the modal visibility.
      this.form_success = false;
    }
  },
  methods: {
    toggleLoginView() {
      this.loginForm = !this.loginForm;
    },
    forgotPassword() {
      this.$router.push({ name: "forgot-password" });
    },
    onSubmit() {
      this.error_messages = {
        email: "",
        password: "",
        name: ""
      };
      if (this.validateInputs()) {
        var formData = new FormData(this.$refs["form"]);
        this.$store.dispatch('login', formData).then(res => {
          this.formSuccess(res);
        }).catch(err => {
          const response = err.response;
          if (response.status == 400 && response.data && response.data.errors) {
            Object.keys(response.data.errors).map((key) => {
              this.error_messages[key] = response.data.errors[key].join(',');
            });
          }
        });
      }
    },
    generateRandomPassword() {
      const password =
        "abcdefhilm!@#noU$%VYZ012WX356789";
      return password;
    },
    onSubmitRegister() {
      this.error_messages = {
        email: "",
        password: "",
        name: ""
      };

      const generatedPassword = this.generateRandomPassword();
      this.$refs.password.value = generatedPassword;


      if (this.validateInputs()) {
        var formData = new FormData(this.$refs["form"]);
        this.$store.dispatch('register', formData).then(res => {
          this.formSuccess(res);
          setTimeout(() => {
            this.$router.push({ name: "home" });
          }, 4000)
        }).catch(err => {
          const response = err.response;
          if (response.status == 400 && response.data && response.data.errors) {
            Object.keys(response.data.errors).map((key) => {
              console.log(response.data.errors[key], key);
              this.error_messages[key] = response.data.errors[key].join(',');
            });
          }
        });
      }
    },
    clearForm() {

    },
    validateInputs() {
      var valid = true;

      // this.inputs.forEach(input => {
      //     var inputField = this.$refs[input.name][0];
      //     var errorInput = input.type == 'file' ? this.$refs[input.name+'_file'][0] : inputField;
      //     errorInput.classList.remove("error");
      //     if(input.required && !inputField.value){
      //         valid = false;
      //         errorInput.classList.add("error");
      //     }
      // });

      return valid;
    },
    formSuccess() {
      this.clearForm();
      this.form_success = true;
      this.$emit('form-success');
    }
  }
}
</script>
<style scoped lang="scss">
.fileIcon {
  font-size: 2.5rem;
}

.fake-btn {
  background-color: #000411;
  height: calc(1.8em + 0.75rem + 2px);
  padding: 0.5rem 0.75rem;
  font-weight: 500;
  color: #FFFFFF;
  border: 1px solid #ced4da;

  &:hover {
    background-color: #000000;
  }
}

.fake-btn.error,
.form-control.error {
  border: 1px solid rgb(126, 46, 46);
}

.btn {
  padding: 0.5rem 0.75rem;
  //color: #FFFFFF;
  font-weight: 500;
}

.form-control {
  // background-color: #000411;
  background-color: inherit;
  height: calc(2.5em + 0.75rem + 2px);
  padding: 0.75rem 0.9rem;
  font-weight: 500;
  color: #FFFFFF;
  border-color: white;

  &:focus {
    // background-color: #000411;
    background-color: inherit;
    color: #EEEEEE;
  }
}

textarea {
  min-height: 90px;
  max-height: 250px;
}

.text-underlined {
  text-decoration: underline;
}

.forgot-pass {
  margin-top: 1rem;
  color: #ced4da;
  font-size: 0.8rem;
  float: right;
}
</style>